.form-selectable-page-lightbox-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: #0000004d;

  .lightbox {
    background-color: #fff;
    width: 350px;
    max-width: 95%;
    height: fit-content;
    padding: 35px 15px;
    text-align: center;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
}

.form-page-link-btn:hover {
  color: #e95d0f !important;
  font-weight: bold !important;
}

.form-pagination-btn {
  border-radius: 9px;
  width: 32px;
  min-width: fit-content;
  height: 32px;
  border: none;
  background-color: #ebedf1;
  color: #6c849c;

  &.activate-page-btn {
    background-color: #6c849c;
    color: #fff;
  }

  &:hover {
    border: solid 1px #3f5a7c;
  }
}

.form-page-link-btn {
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: bold;

  &:hover {
    color: #e95d0f !important;
    font-weight: bold !important;
  }
}

.form-content-item {
  border: solid 1px #000;
  border-radius: 12px;
  padding: 12px;
  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: #002350;
  word-break: break-all;

  .page-thumbnail-img {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .page-number {
    font-size: 16px;
    display: block;
  }

  .page-title {
    font-size: 12px;
    display: block;
  }
}