.custom-card {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  width: 100%;
  height: 100%;
  max-width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  .custom-card-img-container {
    display: flex;
    position: relative;
    width: 100%;
    padding-top: 100%;
  }

  .custom-card-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-style: none;
    object-fit: cover;
    background-color: #EFEFEF;
  }
  
  .custom-card-body {
    flex: 1;
    min-height: 0;
    padding: 1.5rem;
    width: 100%;
    font-size: calc( 5% + 10px );
    position: relative;
    overflow: hidden;

    &>div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  .custom-card-footer {
    min-height: fit-content;
    display: flex;
    flex-wrap: wrap;
    padding: 0.25rem 1.25rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 10px;
    text-transform: capitalize;
    white-space: nowrap;
    
    button {
      font-size: calc( 5% + 10px );
    }
  }

  .custom-card-subtext {
    font-size: 0.8rem;
  }

  .custom-card-title {
    font-size: calc( 20% + 25px );
    font-weight: 600;
    font-family: 'Open Sans Condensed', sans-serif;
    padding: 1rem 0rem;
    margin-bottom: 8px;
  }

  .custom-card-floating-bar {
    position: absolute;
    top: 97%;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    cursor: default;

    .tag {
      background-color: #FA6C32;
      color: #fff;
      padding: 3px 15px;
      margin: 0px 2px;
      max-width: 118px;
      min-width: fit-content;
      font-size: 14px;
      font-weight: 200;
    }
  }
}