$primary-btn-color: #FA6C32;
$secondary-btn-color: #828282;

@mixin btn {
  display: inline-block;
  font-weight: 200;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 3px 15px;
  font-size: 1rem;
  line-height: 1.5;
  transition: 0.5s;
  max-width: 100%;
}

.pill-shaped {
  border-radius: 50px !important;
  background-color :#FA6C32 !important;
  color: white !important;
  border: none;
}

.filled-btn {
  @include btn;
  border: 1px solid $primary-btn-color;
  background-color: $primary-btn-color;
  color: #fff;

  &:hover {
    border: 1px solid $primary-btn-color;
    background-color: transparent;
    color: $primary-btn-color;
  }

  &:disabled { 
    border: 1px solid #CCCCCC;
    background-color: #CCCCCC;
    color: #828282;
    cursor: deafult;
  }

  &--secondary {
    @extend .filled-btn;
    border-color: $secondary-btn-color;
    background-color: $secondary-btn-color;
    &:hover {
      border-color: $secondary-btn-color;
      color: $secondary-btn-color;
    }
  }
}

.outline-btn {
  @include btn;
  border: 1px solid $primary-btn-color;
  background-color: transparent;
  color: $primary-btn-color;

  &:hover {
    border: 1px solid $primary-btn-color;
    background-color: $primary-btn-color;
    color: #fff;
  }

  &:disabled { 
    border: 1px solid #CCCCCC;
    background-color: #CCCCCC;
    color: #828282;
    cursor: deafult;
  }

  &--secondary {
    @extend .outline-btn;
    border-color: $secondary-btn-color;
    color: $secondary-btn-color;
    &:hover {
      border-color: $secondary-btn-color;
      background-color: $secondary-btn-color;
    }
  }
}

.transparent-btn {
  @include btn;
  border-color: transparent;
  background-color: transparent;
}

.img-btn {
  @include btn;
  border: none;
  color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  transform-origin: center;
  transition: transform .4s ease-in-out;

  &:hover {
    background-size: contain;
  }
}