.carousel-button-group {
  color: #FA6C32;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  
  .carousel__back-button, .carousel__next-button {
    font-size: 25px;
    padding: 0;
    margin-bottom: -9px;
  }

  .carousel-dot-list {
    max-width: calc( 100% - 120px );
    margin-bottom: 4px;
    
    .carousel__dot {
      border: solid 1px #FA6C32;
      outline: none;
      background-color: transparent;
      border-radius: 100%;
      width: 10px;
      height: 10px;
      padding: 0;
      margin: 0px 3px;
    }

    .carousel__dot--selected {
      background-color: #FA6C32;
    }
  }
}

.carousel-card-item {
  padding: 0.5rem;
  display: flex;
  justify-content: center;
}

.carousel-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.carousel {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.carousel__slider {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.carousel__slider > div {
  height: 100%;
}

.carousel__slider > div > ul {
  height: 100%;
}