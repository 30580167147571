@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import './functions_override';

body {
  margin: 0;
  --ck-z-default: 100;
  --ck-z-modal: calc( var(--ck-z-default) + 999 );
}

$primary: var(--primary);

$theme-colors: (
  "primary": var(--primary)
);

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

.metismenu-link {
  &:hover {
    color: var(--primary) !important;
  }

  &.active {
    color: var(--primary) !important;
  }
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: var(--primary) !important;
}

.ReactTable .rt-thead .rt-th {
  color: var(--primary);
}

.ReactTable .-pagination .-btn {
  color: var(--primary);
}

.RRT__tab--selected {
  color: var(--primary);
}

.ball-pulse-rise > div {
  background-color: var(--primary);
}

.app-sidebar__heading {
  color: var(--primary) !important;
}